/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { KmmButton } from '../../components/KmmButton';

interface IProps {
  showModal: () => void;
  onScroll: (to: number) => void;
}

const KmmFooter: FC<IProps> = ({ showModal, onScroll }) => (
  <div className={styles.wrap}>
    <div className={styles.title_text}>
      <h3>{t('cases.cource.header.name')}</h3>
      <h4>{t('cases.cource.header.title')}</h4>
      <p>{t('cases.cource.header.desc')}</p>
      <KmmButton onClick={showModal}>
        {t('cases.cource.header.button')}
      </KmmButton>
    </div>
    <div className={styles.footer_item}>
      <p>{t('cases.cource.footer.copywriter')}</p>
      <nav>
        <button
          onClick={() => {
            onScroll(1500);
          }}
        >
          <p>{t('cases.cource.header.advantages')}</p>
        </button>
        <button
          onClick={() => {
            onScroll(2100);
          }}
        >
          <p>{t('cases.cource.header.aboutUS')}</p>
        </button>
        <button
          onClick={() => {
            onScroll(2700);
          }}
        >
          <p>{t('cases.cource.header.program')}</p>
        </button>
        <button
          onClick={() => {
            onScroll(4300);
          }}
        >
          <p>{t('cases.cource.header.faq')}</p>
        </button>
      </nav>
    </div>
  </div>
);

export { KmmFooter };
