/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { KmmButton } from '../../components/KmmButton';
import { usePathPrefix } from '../../../../../constants/hooks';
import { KmmBreadcrumbs } from '../../components/KmmBreadcrumbs';
import { WhyKotlin } from '../WhyKotlin';

interface IProps {
  title: string;
  showModal: () => void;
  onScroll: (to: number) => void;
}

const KmmTitle: FC<IProps> = ({ title, showModal, onScroll }) => {
  const prefix = usePathPrefix();
  return (
    <div className={styles.wrap}>
      <div className={styles.breadcrumbs}>
        <div>
          <KmmBreadcrumbs title={title} />
        </div>
        <div className={styles.nav}>
          <button
            onClick={() => {
              onScroll(1500);
            }}
          >
            {t('cases.cource.header.advantages')}
          </button>
          <button
            onClick={() => {
              onScroll(2100);
            }}
          >
            {t('cases.cource.header.aboutUS')}
          </button>
          <button
            onClick={() => {
              onScroll(2700);
            }}
          >
            {t('cases.cource.header.program')}
          </button>
          <button
            onClick={() => {
              onScroll(4300);
            }}
          >
            {t('cases.cource.header.faq')}
          </button>
        </div>
      </div>
      <div className={styles.title_content}>
        <div className={styles.title_text}>
          <h3>{t('cases.cource.header.name')}</h3>
          <h4>{t('cases.cource.header.title')}</h4>
          <p>{t('cases.cource.header.desc')}</p>
          <KmmButton onClick={showModal}>
            {t('cases.cource.header.button')}
          </KmmButton>
        </div>
        <div className={styles.image}>
          <img src={`${prefix}/case/cource/title.png`} alt="" />
        </div>
      </div>
      <WhyKotlin />
    </div>
  );
};

export { KmmTitle };
