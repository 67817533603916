import React, { FC } from 'react';
import { usePathPrefix } from '../../../../../constants/hooks';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface IProps {
  title: string;
  desc: string;
}

const KmmCause: FC<IProps> = ({ desc, title }) => {
  const prefix = usePathPrefix();
  return (
    <div className={styles.cause}>
      <div className={styles.star}>
        <img src={`${prefix}/case/cource/star.svg`} alt="" />
      </div>
      <div className={styles.cause__title}>{t(title)}</div>
      <p className={styles.cause__desc}>{t(desc)}</p>
    </div>
  );
};

export { KmmCause };
