/* eslint-disable no-param-reassign */
/* eslint-disable radix */
import React, { FC, useCallback, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { KmmTitle } from '../../../../components/directions/cource/screens/KmmTitle';
import { KmmContactModal } from '../../../../components/directions/cource/screens/KmmContactModal';
import { KmmSuccessModal } from '../../../../components/directions/cource/screens/KmmSuccessModal';
import { IDirectionTagsQuery } from '../../../../queries/directionTags/types';
import { KmmWhyIcerock } from '../../../../components/directions/cource/screens/KmmWhyIcerock';
import { AboutUs } from '../../../../components/directions/cource/screens/AboutUs';
import { Steps } from '../../../../components/directions/cource/screens/Steps';
import { WhatAwaitsYou } from '../../../../components/directions/cource/screens/WhatAwaitsYou';
import { KmmFooter } from '../../../../components/directions/cource/screens/KmmFooter';
import styles from './access/css/styles.module.scss';
import { useSSRSafeWindow } from '../../../../constants/hooks';

type IProps = PageProps<
  IDirectionTagsQuery,
  { locale: string; tag: string; page: number }
>;

const KmmPage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'en', tag = '', page = 0 },
}) => {
  const direction = data.directionTags.edges.find((edge) =>
    location.pathname.includes(edge.node.frontmatter.url)
  );
  const [isModalShown, setIsModalShown] = useState(false);
  const [isSuccessShown, setIsSuccessShown] = useState(false);

  const toggleModal = useCallback(
    () => setIsModalShown(!isModalShown),
    [isModalShown, setIsModalShown]
  );

  const toggleSuccess = useCallback(
    () => setIsSuccessShown(!isSuccessShown),
    [isSuccessShown, setIsSuccessShown]
  );

  const win = useSSRSafeWindow();

  const onScroll = (to: number) => {
    let i = parseInt(String(win?.pageYOffset));
    if (i !== to) {
      to = parseInt(String(to));
      if (i < to) {
        const int = setInterval(() => {
          if (i > to - 20) i += 1;
          else if (i > to - 40) i += 3;
          else if (i > to - 80) i += 8;
          else if (i > to - 160) i += 18;
          else if (i > to - 200) i += 24;
          else if (i > to - 300) i += 40;
          else i += 60;
          win?.scroll(0, i);
          if (i >= to) clearInterval(int);
        }, 15);
      } else {
        const int = setInterval(() => {
          if (i < to + 20) i -= 1;
          else if (i < to + 40) i -= 3;
          else if (i < to + 80) i -= 8;
          else if (i < to + 160) i -= 18;
          else if (i < to + 200) i -= 24;
          else if (i < to + 300) i -= 40;
          else i -= 60;
          win?.scroll(0, i);
          if (i <= to) clearInterval(int);
        }, 15);
      }
    }
  };

  return (
    <MainLayout locale={locale} title="menu.directions">
      <div className={styles.wrap_kmm}>
        <KmmTitle
          title={direction?.node.frontmatter.name ?? ''}
          showModal={toggleModal}
          onScroll={onScroll}
        />
        <KmmWhyIcerock />
        <AboutUs />
        <Steps showModal={toggleModal} />
        <WhatAwaitsYou />
        <KmmFooter showModal={toggleModal} onScroll={onScroll} />
        {isModalShown && (
          <KmmContactModal onClose={toggleModal} onSuccess={toggleSuccess} />
        )}
        {isSuccessShown && <KmmSuccessModal onClose={toggleSuccess} />}
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query {
    directionTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/directions/tags/" } }
    ) {
      ...DirectionTagsFields
    }
  }
`;

export default KmmPage;
