import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { KmmFAQ } from '../KmmFAQ';

interface FitProps {
  img: string;
  title: string;
  desc: string;
}

const ExpectationItem: FC<FitProps> = ({ img, title, desc }) => (
  <div className={styles.expectation_item}>
    <div className={styles.expectation_item_image}>
      <img src={img} alt="" />
    </div>
    <div className={styles.expectation_item_text}>
      <h3>{t(title)}</h3>
      <p>{t(desc)}</p>
    </div>
  </div>
);

const WhatAwaitsYou = () => {
  const prefix = usePathPrefix();

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.title_content}>
          <div className={styles.title}>
            <h3>{t('cases.cource.whatAwaits.title')}</h3>
          </div>
          <div className={styles.expectation_list}>
            <ExpectationItem
              img={`${prefix}/case/cource/new_level.svg`}
              desc="cases.cource.whatAwaits.await_1_desc"
              title="cases.cource.whatAwaits.await_1_title"
            />
            <ExpectationItem
              img={`${prefix}/case/cource/experience.svg`}
              desc="cases.cource.whatAwaits.await_2_desc"
              title="cases.cource.whatAwaits.await_2_title"
            />
            <ExpectationItem
              img={`${prefix}/case/cource/fee.svg`}
              desc="cases.cource.whatAwaits.await_3_desc"
              title="cases.cource.whatAwaits.await_3_title"
            />
            <ExpectationItem
              img={`${prefix}/case/cource/interesting_projects.svg`}
              desc="cases.cource.whatAwaits.await_4_desc"
              title="cases.cource.whatAwaits.await_4_title"
            />
            <ExpectationItem
              img={`${prefix}/case/cource/management.svg`}
              desc="cases.cource.whatAwaits.await_5_desc"
              title="cases.cource.whatAwaits.await_5_title"
            />
          </div>
        </div>
        <KmmFAQ />
      </div>
    </>
  );
};

export { WhatAwaitsYou };
