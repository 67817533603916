/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { KmmStep } from '../../components/KmmStep';

interface IProps {
  showModal: () => void;
}

const Steps: FC<IProps> = ({ showModal }) => (
  <div className={styles.wrap}>
    <a id="program" />
    <div className={styles.title}>
      <h3>Курс состоит из 12 этапов</h3>
    </div>
    <div className={styles.steps}>
      {[...Array(12)].map((item, index) => (
        <KmmStep
          index={index + 1}
          title={`cases.cource.steps.step_${index + 1}`}
        />
      ))}
    </div>
    <div className={styles.btn}>
      <button className={styles.button} onClick={showModal}>
        {t('cases.cource.steps.button')}
      </button>
    </div>
  </div>
);

export { Steps };
