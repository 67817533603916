type formInput = Record<string, string>;

const formInputs: formInput[] = [
  {
    nameInput: 'name',
    label: 'cases.cource.form.name',
    icon: '/case/restocare/contact_name.svg',
  },
  {
    nameInput: 'email',
    label: 'cases.cource.form.email',
    icon: '/case/restocare/contact_email_kmm.svg',
  },
  {
    nameInput: 'phone',
    icon: '/case/restocare/contact_phone.svg',
  },
  {
    nameInput: 'comment',
    label: 'cases.cource.form.comment',
    icon: '/case/restocare/contact_comment.svg',
  },
];

export default formInputs;
