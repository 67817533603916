import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';

interface FitProps {
  img: string;
  title: string;
}

const FitItem: FC<FitProps> = ({ img, title }) => (
  <div className={styles.fit_item}>
    <div className={styles.fit_item_image}>
      <img src={img} alt="" />
    </div>
    <div className={styles.fit_item_text}>
      <p>{t(title)}</p>
    </div>
  </div>
);

const WhyKotlin = () => {
  const prefix = usePathPrefix();

  return (
    <div className={styles.wrap}>
      <div className={styles.title_content}>
        <div className={styles.title}>
          <h3>{t('cases.cource.whyKotlin.title')}</h3>
        </div>
        <div className={styles.desc}>
          <p>
            <h4>{t('cases.cource.whyKotlin.desc_1')}</h4>
          </p>
          <p>
            <h4>{t('cases.cource.whyKotlin.desc_2')}</h4>
          </p>
        </div>
      </div>
      <div className={styles.fit_content}>
        <div className={styles.fit_title}>
          <h3>{t('cases.cource.whyKotlin.willDo')}</h3>
        </div>
        <div className={styles.fit_list}>
          <FitItem
            img={`${prefix}/case/cource/graduates.svg`}
            title="cases.cource.whyKotlin.graduates"
          />
          <FitItem
            img={`${prefix}/case/cource/developers.svg`}
            title="cases.cource.whyKotlin.develiopers"
          />
          <FitItem
            img={`${prefix}/case/cource/ProductManager.svg`}
            title="cases.cource.whyKotlin.manager"
          />
        </div>
      </div>
    </div>
  );
};

export { WhyKotlin };
