/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { TLink } from '../../../../../i18n/TLink';

const AboutUs = () => {
  const prefix = usePathPrefix();

  return (
    <div className={styles.wrap}>
      <a id="aboutUS" />
      <div className={styles.title_content}>
        <div className={styles.image}>
          <img src={`${prefix}/case/cource/AboutUs.png`} alt="" />
        </div>
        <div className={styles.title_text}>
          <h3>{t('cases.cource.aboutUs.title')}</h3>
          <h4>{t('cases.cource.aboutUs.desc_1')}</h4>
          <p>{t('cases.cource.aboutUs.desc_2')}</p>
          <TLink to="/cases">
            <button className={styles.button}>
              {t('cases.cource.aboutUs.button')}
              <img src={`${prefix}/case/cource/arrow_right.svg`} alt="" />
            </button>
          </TLink>
        </div>
      </div>
    </div>
  );
};

export { AboutUs };
