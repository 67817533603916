/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styles from './styles.module.scss';
import { KmmCause } from '../../components/KmmCause';
import { t } from '../../../../../i18n';

const KmmWhyIcerock = () => {
  const causes = [1, 2, 3, 4, 5, 6];
  return (
    <div className={styles.wrap}>
      <a id="advantages" />
      <div className={styles.title}>
        <h3>{t('cases.cource.whyIcerock.title')}</h3>
      </div>
      <div className={styles.causes}>
        {causes.map((item) => (
          <KmmCause
            title={`cases.cource.whyIcerock.cause_${item}_title`}
            desc={`cases.cource.whyIcerock.cause_${item}_desc`}
          />
        ))}
      </div>
    </div>
  );
};

export { KmmWhyIcerock };
