import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface IProps {
  title: string;
  index: number;
}

const KmmStep: FC<IProps> = ({ index, title }) => (
  <div className={styles.step}>
    <p className={styles.step__index}>
      <span>{index}</span>
    </p>
    <p className={styles.step__title}>{t(title)}</p>
  </div>
);

export { KmmStep };
